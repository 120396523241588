exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-barristers-tsx": () => import("./../../../src/pages/barristers.tsx" /* webpackChunkName: "component---src-pages-barristers-tsx" */),
  "component---src-pages-clerks-tsx": () => import("./../../../src/pages/clerks.tsx" /* webpackChunkName: "component---src-pages-clerks-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-practice-areas-tsx": () => import("./../../../src/pages/practice-areas.tsx" /* webpackChunkName: "component---src-pages-practice-areas-tsx" */),
  "component---src-templates-barrister-tsx": () => import("./../../../src/templates/barrister.tsx" /* webpackChunkName: "component---src-templates-barrister-tsx" */),
  "component---src-templates-clerk-tsx": () => import("./../../../src/templates/clerk.tsx" /* webpackChunkName: "component---src-templates-clerk-tsx" */),
  "component---src-templates-event-item-tsx": () => import("./../../../src/templates/eventItem.tsx" /* webpackChunkName: "component---src-templates-event-item-tsx" */),
  "component---src-templates-news-item-tsx": () => import("./../../../src/templates/newsItem.tsx" /* webpackChunkName: "component---src-templates-news-item-tsx" */),
  "component---src-templates-static-page-tsx": () => import("./../../../src/templates/staticPage.tsx" /* webpackChunkName: "component---src-templates-static-page-tsx" */)
}

